import React from 'react'
import { graphql } from 'gatsby'
import Img, { FixedObject } from 'gatsby-image'
import { Helmet } from 'react-helmet'
import { Link } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import { IndexQueryQuery, PostByPathQuery } from '../../../types/graphql-types'
import Layout from 'components/layout/layout'
import Container from 'components/pages/container'
import ContainerFluid from 'components/pages/container-fluid'
import Video from 'components/video'
import OGImage from 'images/McCordsville-Dentist.png'
import Logo from 'images/logo.png'

interface Props {
  data: IndexQueryQuery
  location: Location
}

const DrSummersPage: React.FC<Props> = ({ data, location }: Props) => {
  const hero = data.hero?.childImageSharp?.fluid
  const hero_background = data.hero_background?.childImageSharp?.fluid
  const hero_dr_img = data.hero_dr_img?.childImageSharp?.fluid
  const section3_img = data.section3_img?.childImageSharp?.fluid
  const section4_background = data.office_section4_background?.childImageSharp?.fluid

  return (
    <Layout location={location}>
    <Helmet>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <title>Dr. Summers  - The Dentists At Gateway Crossing</title>
      <meta name="description" content="Doctor Of Dental Surgery" />
      <meta name="robots" content="index, follow, max-snippet:-1, max-video-preview:-1, max-image-preview:large" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:type" content="article" />
      <meta property="og:title" content="Dr. Summers - The Dentists At Gateway Crossing" />
      <meta property="og:description" content="Doctor Of Dental Surgery" />
      <meta property="og:url" content="https://thedentistsatgc.com/about/dr-summers/" />
      <meta property="og:site_name" content="The Dentists At Gateway Crossing" />
      <meta property="article:publisher" content="https://www.facebook.com/thedentistsatgc/" />
      <meta property="og:updated_time" content="2021-02-05T05:10:25+00:00" />
      <meta property="og:image" content={OGImage} />
      <meta property="og:image:secure_url" content={OGImage} />
      <meta property="og:image:width" content="400" />
      <meta property="og:image:height" content="600" />
      <meta property="og:image:alt" content="Dentist in McCordsville" />
      <meta property="og:image:type" content="image/png" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:title" content="Dr. Summmers - The Dentists At Gateway Crossing" />
      <meta name="twitter:description" content="Doctor Of Dental Surgery" />
      <meta name="twitter:image" content={OGImage} />
      <meta name="google-site-verification" content="khYQGQ-T8YWkXa0QyKr6e2kNlFB3l0ZiFRA89VzVbaM" />
      <meta name="google-site-verification" content="Oll6PjpMRzNx1KbgKilxS6xPddvSbF2lDOa2rd7VkxA" />
      <meta name="msapplication-TileImage" content={Logo} />
      <script async src='https://tag.simpli.fi/sifitag/195cd770-bf42-013a-5143-0cc47a8ffaac'></script>
    </Helmet>
      <BackgroundImage
        Tag="section"
        className="hero-section-bg hero-section-dr"
        fluid={hero_background}
        backgroundColor={`#e9f4f8`}
        alt="The Dentists at Gateaway Crossing"
      >
        <div className ="col-lg-12" id ="color-overlay">
        </div>
        <div id="hero-section">
          <Container>
            <div className="col-lg-8 text-center">
              <p className="hero-text">
                MEET YOUR MCCORDSVILLE DENTIST
              </p>
              <h1 className="text-light">
                Dr. Maria Summers
              </h1>
              <a href="#dr-section-1"><button className="btn-rounded m-t-35"> Learn More </button></a>
              <Link to="/contact"><button className="btn-rounded m-t-35"> Schedule Now </button></Link>
            </div>
            <div className="col-lg-4 text-center">
              <Img fluid ={hero_dr_img} alt="Dr. Maria Summers - McCordsville Dentist" />
            </div>
          </Container>
        </div>
      </BackgroundImage>
      <section id ="dr-section-1" className="page-section text-left">
          <Container>
            <div className="col-lg-4">
              <Img fluid ={section3_img} alt="Dr. Maria Summers and the McCordsville Dentists" />
            </div>
            <div className="col-lg-8">
              <p className ="section-1-text">DOCTOR OF DENTAL SURGERY </p>
              <h2 className ="section-1-header">Meet Dr. Maria Summers</h2>
              <p>Dr. Maria Summers was born in Venezuela and moved to the United States when she was 15 years old with her parents and brother. She speaks both English and Spanish.</p>
              <p>She graduated from the University of Central Florida with a degree in Biomedical Sciences, then decided to leave the Florida sunshine and venture to Indianapolis, where she attended Indiana University School of Dentistry and graduated with a degree of Doctor of Dental Surgery. While in school, Dr. Summers enjoyed being involved in student organizations and was also part of the Indiana National Guard as a Dental Officer, from which she is now retired. </p>
            </div>
          </Container>
          <Container>
          <div className="col-lg-12">
              <p className="m-t-20">Dr. Summers' mission is to deliver the highest possible level of dental care to patients, with the intent that her compassion and comprehensiveness will build trusting relationships with the community. She strives to educate and inspire patients to optimize their oral health in order to achieve and maintain a beautiful, healthy smile. </p>
              <p>She is married to Brandon and is a dog mom to two wonderful labs named Ace and Mackey. (Go Boilers!) Some of the things she enjoys are eating, traveling, playing tennis and watching Formula 1 races. Her passion for tennis even led her to play on the Venezuelan National Team until she moved to the United States.</p>
              <p>Dr. Summers and our team look forward to meeting you!</p>
            </div>
          </Container>
        </section>
        <BackgroundImage
            Tag="section"
            className="office_section4-section-bg"
            fluid={section4_background}
            backgroundColor={`#e9f4f8`}
            alt="The Dentists at Gateaway Crossing Book an Appointment"
          >
            <div className ="col-lg-12" id ="color-overlay-dark"></div>
            <section id="section-4" className="page-section text-center">
              <Container>
                <div className="col-lg-12 ">
                  <button className="btn-rounded"> Book An Appointment </button>
                </div>
              </Container>
              </section>
        </BackgroundImage>
    </Layout>
  )
}

export default DrSummersPage

export const query = graphql`
  query DrSummersPageQuery {
    hero: file(name: { eq: "hero-image" }) {
      childImageSharp {
        fluid(maxWidth: 800, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    product1: file(relativePath: { eq: "images/marco-pharma.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 300, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_dr_img: file(name: { eq: "best-maria-s" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section3_img: file(name: { eq: "dr-summers" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    sec1_img: file(name: { eq: "dr-vogt-img1" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    sec2_img: file(name: { eq: "dr-vogt-img2" }) {
      childImageSharp {
        fluid(maxWidth: 1000, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    section4_background: file(name: { eq: "section_4_bg" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    },
    hero_background: file(name: { eq: "about-us-hero" }) {
      childImageSharp {
        fluid(maxWidth: 1400, quality: 90) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
